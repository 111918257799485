import React, { useState, useEffect } from "react";
import { permitHolderService } from "../functions/services";
import Cookies from "js-cookie";
import { authHeader } from '../functions/_helpers';

function SummaryPage() {
  const [licenseHolders, setLicenseHolders] = useState([]);
  const [selectedLicenseHolderId, setSelectedLicenseHolderId] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(getPreviousMonth());
  const [summaryData, setSummaryData] = useState([]);
  const [licenseHolderName, setLicenseHolderName] = useState("");
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [systemParameters, setSystemParameters] = useState([]);

  const [articleLabels, setArticleLabels] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [suppliers, setSuppliers] = useState([]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    console.log("Code fra URL:", code);

    if (code) {
      exchangeCodeForToken(code);
    } else {
      authenticateWithVisma();
    }
    fetchSystemParameters();
  }, []);

  const fetchLicenseHolderDetails = async () => {
    try {
      const token = getTokenFromCookies();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/Visma/PermitHoldersWithCustomerAndSupplierIds`,
        {
          headers: {
            ...authHeader(), 
            "X-Visma-Token": token, 
          },
        }
      );
  
      if (!response.ok) {
        throw new Error("Kunne ikke hente permit holders fra API-et.");
      }
  
      const data = await response.json();
      console.log("Permit holders with customer and supplier IDs:", data);
      setLicenseHolders(data);
    } catch (error) {
      console.error("Feil ved henting av permit holders:", error.message);
      setError("Kunne ikke hente permit holders. Prøv igjen senere.");
    }
  };

  const fetchSystemParameters = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/Rig/GetSystemParameters`,
        {
          headers: {
            ...authHeader()
          },
        }
      );
  
      if (!response.ok) {
        throw new Error("Kunne ikke hente systemparametere.");
      }
  
      const data = await response.json();
      console.log("Systemparametere:", data);
      setSystemParameters(data); 
    } catch (error) {
      console.error("Feil ved henting av systemparametere:", error.message);
      setError("Kunne ikke hente systemparametere. Prøv igjen senere.");
    }
  };

  const authenticateWithVisma = () => {
    console.log("Starter autentisering med Visma.");
    const authorizationUrl = `https://identity.vismaonline.com/connect/authorize?response_type=code&client_id=intercabas&redirect_uri=https://localhost:44300/callback&scope=ea:api offline_access ea:sales ea:purchase
`;
    window.location.href = authorizationUrl;
  };

  const getTokenFromCookies = () => {
    return Cookies.get("access_token") || localStorage.getItem("access_token");
  };

  const saveTokenToCookies = (key, token, options = {}) => {
    const isProduction = process.env.NODE_ENV === "production";
    const defaultOptions = isProduction
      ? { secure: true, sameSite: "None" }
      : {};
    Cookies.set(key, token, { ...defaultOptions, ...options });

    localStorage.setItem(key, token);
  };

  const exchangeCodeForToken = async (code) => {
    try {
      console.log("Bytter kode for token:", code);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/Visma/exchange-token`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ Code: code }),
        }
      );

      if (!response.ok) {
        const responseData = await response.json();
        throw new Error(
          `Feil under token-utveksling: ${response.statusText}. Detaljer: ${JSON.stringify(
            responseData
          )}`
        );
      }

      const responseData = await response.json();
      console.log("Respons fra tokenutveksling:", responseData);

      saveTokenToCookies("access_token", responseData.access_token);
      saveTokenToCookies("refresh_token", responseData.refresh_token, {
        expires: 7,
      });

      const storedToken = Cookies.get("access_token");
      if (storedToken) {
        setIsAuthenticated(true);
        console.log("Autentisering vellykket.");
      } else {
        throw new Error("Token ble ikke lagret riktig.");
      }
    } catch (error) {
      console.error("Feil under tokenutveksling:", error.message);
      setError("Autentisering feilet. Prøv igjen.");
    }
  };

  useEffect(() => {
    const token = getTokenFromCookies();
    if (token) {
      setIsAuthenticated(true);
    } else {
      authenticateWithVisma();
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchArticleLabels();
      fetchLicenseHolderDetails();

    }
  }, [isAuthenticated]);

  const fetchArticleLabels = async () => {
    try {
      const token = getTokenFromCookies();
      const response = await fetch(
        `https://eaccountingapi.vismaonline.com/v2/articles`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Kunne ikke hente Articles.");
      }

      const data = await response.json();
      console.log("Article Labels:", data);
      setArticleLabels(data.Data);
    } catch (error) {
      console.error("Feil ved henting av Articles:", error.message);
    }
  };

  useEffect(() => {
    if (!selectedLicenseHolderId) return;

    const fetchLicenseHolderDetails = async () => {
      try {
        const data = await permitHolderService.getPermitHolder(
          selectedLicenseHolderId
        );
        setLicenseHolderName(data.name);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchLicenseHolderDetails();
  }, [selectedLicenseHolderId]);

  useEffect(() => {
    if (!selectedLicenseHolderId || !selectedMonth) return;

    const fetchSummary = async () => {
      try {
        const [year, month] = selectedMonth.split("-");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/rig/${selectedLicenseHolderId}/invoice-summary?year=${year}&month=${month}`,
          { headers: { Authorization: `Bearer ${getTokenFromCookies()}` } }
        );
        if (!response.ok) {
          throw new Error("Kunne ikke hente summering for valgt måned.");
        }
        const data = await response.json();
        setSummaryData(data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchSummary();
  }, [selectedLicenseHolderId, selectedMonth]);

  function getPreviousMonth() {
    const now = new Date();
    const prevMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    return `${prevMonth.getFullYear()}-${String(
      prevMonth.getMonth() + 1
    ).padStart(2, "0")}`;
  }

  if (!isAuthenticated) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p>Vennligst vent. Autentiserer mot Visma...</p>
      </div>
    );
  }

  return (
    <div className="summary-page">
      {error && <div className="error text-red-500">{error}</div>}

      <h1 className="text-2xl font-bold mb-4">
        {licenseHolderName || "Oppsummering av løyvehavere"}
      </h1>

      <div className="filters mb-4">
        <label htmlFor="licenseHolder" className="block mb-2">
          Velg løyvehaver
        </label>
        <select
          id="licenseHolder"
          className="p-2 border border-gray-300 rounded mb-4"
          value={selectedLicenseHolderId || ""}
          onChange={(e) => setSelectedLicenseHolderId(e.target.value)}
        >
          {licenseHolders.map((holder) => (
            <option key={holder.permitHolderId} value={holder.permitHolderId}>
              {holder.company}
            </option>
          ))}
        </select>

        <label htmlFor="month" className="block mb-2">
          Velg måned
        </label>
        <input
          id="month"
          type="month"
          className="p-2 border border-gray-300 rounded mb-4"
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
        />
      </div>

      <div className="summary-data">
        <h2 className="text-lg font-bold mb-4">Oppsummering:</h2>
        {summaryData.length === 0 ? (
          <p>Ingen data funnet for valgt måned.</p>
        ) : (
          <table className="w-full border-collapse border border-gray-400">
            <thead>
              <tr>
                <th className="border border-gray-400 p-2">Løyvenummer</th>
                <th className="border border-gray-400 p-2">
                  Registreringsnummer
                </th>
                <th className="border border-gray-400 p-2">Pasientreiser</th>
                <th className="border border-gray-400 p-2">Skole</th>
                <th className="border border-gray-400 p-2">Ordinær</th>
                <th className="border border-gray-400 p-2">TT</th>
                <th className="border border-gray-400 p-2">General Credit</th>
                <th className="border border-gray-400 p-2">Credit Card</th>
              </tr>
            </thead>
            <tbody>
              {summaryData.map((item, index) => (
                <tr key={index}>
                  <td className="border border-gray-400 p-2">
                    {item.permitNumber}
                  </td>
                  <td className="border border-gray-400 p-2">
                    {item.licenseNumber}
                  </td>
                  <td className="border border-gray-400 p-2">
                    {item.pasientreiser}
                  </td>
                  <td className="border border-gray-400 p-2">{item.skole}</td>
                  <td className="border border-gray-400 p-2">{item.ordinær}</td>
                  <td className="border border-gray-400 p-2">{item.tt}</td>
                  <td className="border border-gray-400 p-2">
                    {item.generalCredit}
                  </td>
                  <td className="border border-gray-400 p-2">
                    {item.creditCard}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default SummaryPage;
